<style lang="scss">
#f-book-now {
  .btn {
    border-radius: 0;
  }
  .table-bg-success {
    background: #b2facb70;
    border-radius: 10px;
  }
  p {
    font-size: 12px;
  }
  .abbre-code {
    display: flex;
    label {
      line-height: 50px;
      width: 255px;
      font-weight: 500;
      font-size: 14px;
    }
  }
  .archival-code {
    display: flex;
    label {
      line-height: 50px;
      width: 255px;
      font-weight: 500;
      font-size: 14px;
    }
  }
  .card {
    .card-body {
      padding: 1.25rem;
      .btn-right {
        float: right;
      }
      .cleft {
        float: left;
      }
    }
  }
  .drug-testing-booknow {
    margin-top: 3%;
  }
}
</style>
<template>
  <section v-if="!loadingData">
    <div class="row" v-if="rateData.service.book_now_note">
      <div class="col-md-12">
        <div
          class="
            alert alert-custom alert-danger-dim alert-shadow
            fade
            show
            gutter-b
          "
          role="alert"
        >
          <div class="alert-text" v-html="rateData.service.book_now_note"></div>
        </div>
      </div>
    </div>
    <h4>{{ rateData.title }}</h4>
    <div class="row" style="margin-top: 15px">
      <!-- updateOri -->
      <div class="col-md-12">
        <div
          v-if="
            !getServiceOriCode.success &&
              service_ori_code_error &&
              updateServiceOriCode != ''
          "
          class="col-md-6 
            alert alert-custom alert-danger-dim alert-shadow
            fade
            show
            gutter-b
          "
          role="alert"
        >
          <div class="alert-text" v-html="getServiceOriCode.msg"></div>
        </div>
      </div>

      <div class="col-6 abbre-code" v-if="getEnableOriRate">
        <label
          >ORI :
          <strong
            class="text-uppercase"
            onchange="change_ori"
            v-if="
              getServiceOriCode.success == true &&
                service_ori_code_error &&
                updateServiceOriCode != ''
            "
            >{{ updateServiceOriCode }}</strong
          >
        </label>
        <div
          class="input-group mb-3"
          :hidden="getServiceOriCode.success == true"
        >
          <input
            class="form-control text-uppercase"
            v-model="service_ori"
            type="text"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
            @input="updateOri"
          />
          <div class="input-group-append">
            <button
              class="btn btn-success"
              @click="checkServiceOri()"
              type="button"
              style="z-index: 0"
            >
              Use
            </button>
          </div>
        </div>
      </div>

      <div
        class="col-md-12"
        v-if="
          getServiceOriCode.success == true &&
            service_ori_code_error &&
            updateServiceOriCode != ''
        "
        style="margin-top: 10px"
      >
        <div class="row">
          <div class="col-md-5">
            <ul style="list-style: none; padding: 0">
              <li>
                Name :
                <b>{{ getServiceOriCode.data.profession }}</b>
              </li>
              <!-- <li>
                Address : <b> {{ getAbbreviationCode.data.address }}</b>
              </li> -->
              <li class="mt-2">
                <a
                  style="cursor: pointer"
                  @click="removeServiceOri()"
                  class="btn btn-danger"
                >
                  <i class="fa fa-times"></i> Remove ORI
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row" style="margin-top: 15px" v-if="getEnableOriRate">
      <div class="col-12">
        <div
          v-html="getSettingNote.abbreviation_code_note"
          v-if="isBookNowFromSp == false"
        >
          {{ getAbbreviationCode.success }}
        </div>
        <div
          v-if="
            !getAbbreviationCode.success &&
              abbreviation_code_error &&
              updateAbbreviationCode != ''
          "
          class="
            col-6 alert alert-custom alert-danger-dim alert-shadow
            fade
            show
            gutter-b
          "
          role="alert"
        >
          <div class="alert-text" v-html="getAbbreviationCode.msg"></div>
        </div>
      </div>

      <!-- updateAbbreviationCodeFun -->
      <div
        class="col-md-6 col-lg-6 col-sm-12 col-12 abbre-code"
        v-if="isBookNowFromSp == false"
      >
        <label
          >Abbreviation Code :
          <strong
            class="text-uppercase"
            onchange="change_abbr"
            v-if="
              getAbbreviationCode.success == true &&
                abbreviation_code_error &&
                updateAbbreviationCode != ''
            "
            >{{ updateAbbreviationCode }}</strong
          >
        </label>
        <div
          class="input-group mb-3"
          :hidden="getAbbreviationCode.success == true"
        >
          <input
            class="form-control text-uppercase"
            v-model="abree_code"
            v-mask="'XXXXXXXXXX'"
            type="text"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
            @input="updateAbCode"
          />
          <div class="input-group-append">
            <button
              class="btn btn-success"
              @click="checkAbbreviationCode()"
              type="button"
              style="z-index: 0"
            >
              Use
            </button>
          </div>
        </div>
      </div>

      <div
        class="col-md-12"
        v-if="
          getAbbreviationCode.success == true &&
            abbreviation_code_error &&
            updateAbbreviationCode != ''
        "
        style="margin-top: 10px"
      >
        <div class="row">
          <div class="col-md-5">
            <ul style="list-style: none; padding: 0">
              <li>
                Name :
                <b>{{ getAbbreviationCode.data.company_name }}</b>
              </li>
              <li>
                Address : <b> {{ getAbbreviationCode.data.address }}</b>
              </li>
              <li class="mt-2">
                <a
                  style="cursor: pointer"
                  @click="removeAbbCode()"
                  class="btn btn-danger"
                >
                  <i class="fa fa-times"></i> Remove Abbreviation Code
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row"
      v-if="
        (getEnableOriRate &&
          getServiceOriCode.success == true &&
          service_ori_code_error &&
          updateServiceOriCode != '') ||
          !getEnableOriRate
      "
    >
      <div class="col-lg-4 col-md-12 mb-5" v-if="isBookNowFromSp == false">
        <table class="table table-bg-success">
          <thead>
            <tr>
              <th scope="col" style="width: 2%">Qty</th>
              <th scope="col" class="text-right">Price</th>
              <th scope="col" class="text-right">Tax</th>
              <th scope="col" class="text-right">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">{{ rateData.service.default_qty }}</th>
              <td class="text-right">${{ rateData.rate }}</td>
              <td class="text-right" v-if="rateData.service.is_drug_testing">
                ${{
                  (rateData.rate * parseInt(getQty))
                    | taxAmount(rateData.tax_percentage)
                }}
              </td>
              <td class="text-right" v-else>
                ${{
                  (rateData.rate * rateData.service.default_qty)
                    | taxAmount(rateData.tax_percentage)
                }}
              </td>

              <!-- <td class="text-right">${{ rateData | toFixedTwoDigitRate }}</td> -->
              <td class="text-right" v-if="rateData.service.is_drug_testing">
                ${{ getTotalRateAmount }}
                <!-- ${{getItemTotalAmount}} -->
              </td>
              <td class="text-right" v-else>${{ getTotalRateAmount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="col-lg-8 col-md-12 mb-5 row drug-testing-booknow"
        v-if="isBookNowFromSp == false && rateData.service.is_drug_testing"
      >
        <div class="form-group col-lg-4 col-md-7">
          <label for=""><h4>Quantity</h4></label>
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            v-model="qty"
            @change="setQty"
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
          </select>
        </div>
        <!-- <div class="form-group col-lg-4 col-md-7 ">
          <label for="exampleFormControlSelect1"
            ><h4>Multiple Recipients</h4></label
          >
          <select
            class="form-control"
            id="exampleFormControlSelect1"
            v-model="recipient"
            @change="setMultiRecipient"
          >
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        </div> -->
      </div>

      <div
        :class="[
          isBookNowFromSp
            ? 'col-lg-10 col-xl-10 col-md-8'
            : 'col-lg-4 col-md-12'
        ]"
        v-if="
          rateData.has_additional_rate > 0 && !rateData.service.is_drug_testing
        "
      >
        <h4>
          Additional Cards :
          <i
            v-if="isBookNowFromSp == false"
            class="fa fa-minus text-dark"
            @click="miuQty_()"
            style="cursor: pointer"
          ></i>
          <i
            v-if="isBookNowFromSp && getAddQty != 1"
            class="fa fa-minus text-dark"
            @click="miuQty_()"
            style="cursor: pointer"
          ></i>
          <span
            style="
              border: 1px solid #ccc;
              padding-right: 10px;
              padding-left: 10px;
              margin-left: 10px;
              margin-right: 10px;
            "
            >{{ getAddQty }}</span
          >
          <i
            class="fa fa-plus text-dark"
            style="cursor: pointer"
            @click="addQty_()"
          ></i>
        </h4>

        <table class="table table-bg-success">
          <thead>
            <tr>
              <th scope="col" style="width: 2%">Qty</th>
              <th scope="col" class="text-right">Price</th>
              <th scope="col" class="text-right">Tax</th>
              <th scope="col" class="text-right">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">{{ getAddQty }}</th>
              <td class="text-right">${{ rateData.additional_rate }}</td>
              <td class="text-right">
                ${{
                  (rateData.additional_rate * getAddQty)
                    | taxAmount(rateData.tax_percentage)
                }}
              </td>
              <td class="text-right">${{ getTotalAdditionalAmount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- <div class="row" v-else-if="getServiceTypeCode != 'fdle'">
      <h5 v-if="getAbbreviationCode.data.show_payment_option">
        Total: ${{ getTotalServiceAmount }}
      </h5>
    </div> -->
    <div class="row" style="margin-top: 15px" v-if="!getEnableOriRate">
      <div class="col-md-12">
        <div
          v-html="getSettingNote.abbreviation_code_note"
          v-if="isBookNowFromSp == false"
        >
          {{ getAbbreviationCode.success }}
        </div>
        <div
          v-if="
            !getAbbreviationCode.success &&
              abbreviation_code_error &&
              updateAbbreviationCode != ''
          "
          class="col-md-6 
            alert alert-custom alert-danger-dim alert-shadow
            fade
            show
            gutter-b
          "
          role="alert"
        >
          <div class="alert-text" v-html="getAbbreviationCode.msg"></div>
        </div>
      </div>

      <!-- updateAbbreviationCodeFun -->
      <div
        class="col-md-6 col-lg-6 col-sm-12 col-12 abbre-code"
        v-if="isBookNowFromSp == false"
      >
        <label
          >Abbreviation Code :
          <strong
            class="text-uppercase"
            onchange="change_abbr"
            v-if="
              getAbbreviationCode.success == true &&
                abbreviation_code_error &&
                updateAbbreviationCode != ''
            "
            >{{ updateAbbreviationCode }}</strong
          >
        </label>
        <div
          class="input-group mb-3"
          :hidden="getAbbreviationCode.success == true"
        >
          <input
            class="form-control text-uppercase"
            v-model="abree_code"
            v-mask="'XXXXXXXXXX'"
            type="text"
            aria-label="Recipient's username"
            aria-describedby="basic-addon2"
            @input="updateAbCode"
          />
          <div class="input-group-append">
            <button
              class="btn btn-success"
              @click="checkAbbreviationCode()"
              type="button"
              style="z-index: 0"
            >
              Use
            </button>
          </div>
        </div>
      </div>

      <div
        class="col-md-12"
        v-if="
          getAbbreviationCode.success == true &&
            abbreviation_code_error &&
            updateAbbreviationCode != ''
        "
        style="margin-top: 10px"
      >
        <div class="row">
          <div class="col-md-5">
            <ul style="list-style: none; padding: 0">
              <li>
                Name :
                <b>{{ getAbbreviationCode.data.company_name }}</b>
              </li>
              <li>
                Address : <b> {{ getAbbreviationCode.data.address }}</b>
              </li>
              <li class="mt-2">
                <a
                  style="cursor: pointer"
                  @click="removeAbbCode()"
                  class="btn btn-danger"
                >
                  <i class="fa fa-times"></i> Remove Abbreviation Code
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      class="row"
      v-if="
        (getServiceTypeCode == 'fdle' ||
          getServiceTypeCode == 'fingerprintcard') &&
          getArchivalRates.length > 0
      "
    >
      <div class="col-md-6 col-lg-6 col-sm-4 col-6 archival-code">
        <label for="">Archival:</label>

        <select
          class="form-control"
          id="exampleFormControlSelect1"
          v-model="archival_period"
          @change="setArchival"
        >
          <option value="">None</option>

          <option
            v-for="(citem, index) in getArchivalRates"
            :key="index"
            :value="citem.archival_period.id"
          >
            {{ citem.name }}
          </option>
        </select>
      </div>
    </div> -->
    <div class="row">
      <div
        class="col-4"
        v-if="
          (archivalItem != 0 &&
            getEnableOriRate &&
            getServiceOriCode.success == true) ||
            (archivalItem != 0 && !getEnableOriRate)
        "
      >
        <label> Rate</label>
        <table class="table table-bg-success">
          <thead>
            <tr>
              <th scope="col" class="text-right">Price</th>
              <th scope="col" class="text-right">Tax</th>
              <th scope="col" class="text-right">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-right">${{ archivalItem.rate }}</td>
              <td class="text-right">
                ${{
                  archivalItem.rate | taxAmount(archivalItem.tax_percentage)
                }}
              </td>
              <td class="text-right">${{ archival_total }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      class="row"
      v-if="getEnableOriRate && getServiceOriCode.success == true"
    >
      <h5 v-if="getAbbreviationCode.data.show_payment_option">
        Total: ${{ getTotalServiceAmount }}
      </h5>
    </div>
  </section>
</template>
<script>
// import Title from '../../components/frontend/title.vue';
import {
  FETCH_F_CLIENT_RATE,
  FETCH_F_CLIENT_ARCHIVAL_RATES,
  CHECK_F_ABBREVIATION_CODE,
  CHECK_F_SERVICE_ORI_CODE
} from "@/core/services/store/actions.type";
import {
  SET_RATE_TOTAL_AMOUNT,
  SET_ADD_RATE_QTY,
  SET_CHECK_F_ABBREVIATION_CODE,
  UPDATE_PAYMENT_TYPE,
  SET_ARCHIVAL_PERIOD_ID,
  SET_TOTAL_SERVICE_AMOUNT,
  // SET_F_ARCHIVAL_CLIENT_RATE
  SET_ADDITIONAL_CARD_TOTAL_AMOUNT,
  SET_ARCHIVAL_TOTAL_RATE_AMOUNT,
  UPDATE_SERVICE_ORI_CODE,
  SET_CHECK_F_SERVICE_ORI_CODE,
  UPDATE_ORI,
  SET_F_CLIENT_RATE,
  UPDATE_ABBREVIATION_CODE,
  SET_QTY,
  SET_MULTI_RECIPIENT
} from "@/core/services/store/mutations.type";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      addQty: 0,
      addTotalRate: 0,
      abbreviation_code: "",
      abbreviation_code_error: false,
      abree_code: "",
      loadingData: true,
      rateData: [],
      recipient: 0,
      qty: 0,
      archivalData: [],
      archivalItem: [],
      archival_period: "",
      totalAmount: 0,
      archival_total: 0,
      old_addtional_amount: 0,
      service_ori: "",
      service_ori_code_error: false
    };
  },
  computed: {
    ...mapGetters([
      "getTotalServiceAmount",
      "getItemTotalAmount",
      "getSettingNote",
      "getTotalRateAmount",
      "getTotalArchivalRateAmount",
      "getAddQty",
      "getAbbreviationCode",
      "updateAbbreviationCode",
      "getServiceId",
      "isBookNowFromSp",
      "getQty",
      "getMultirecipient",
      "getArchivalRates",
      "getArchivalPeriodId",
      "getTotalAdditionalAmount",
      "getServiceTypeCode",
      "getServiceOriCode",
      "updateServiceOriCode",
      "getEnableOriRate"
    ])
  },

  mounted() {
    this.$emit("startclock", 1);

    this.addQty = this.getAddQty;
    this.$store
      .dispatch(FETCH_F_CLIENT_ARCHIVAL_RATES, this.$route.params.id)
      .then(data => {
        this.archivalData = data;
      });
    if (this.getServiceId != "") {
      this.fetchRate();
      if (this.updateAbbreviationCode != "") {
        this.abree_code = this.updateAbbreviationCode;
        this.checkAbbreviationCode();
      }
      // if (this.updateServiceOriCode != "") {
      //   this.service_ori = this.updateServiceOriCode;
      //   this.checkServiceOri();
      // }
    }
  },

  filters: {
    taxAmount(amount, taxPer) {
      amount = parseFloat(amount);
      taxPer = parseFloat(taxPer);
      return ((amount * taxPer) / 100).toFixed(2);
    },
    toFixedTwoDigit(amount, data, isBookNowFromSp) {
      // alert();
      var rate = 0;
      var tp = 0;
      if (!isBookNowFromSp) {
        rate = parseFloat(data.rate) * parseInt(data.service.default_qty);
        tp = parseFloat(data.tax_percentage);
      }

      return (parseFloat(amount) + (rate + (rate * tp) / 100)).toFixed(2);
    },
    toFixedTwoDigitRate(data) {
      var rate = parseFloat(data.rate) * parseInt(data.service.default_qty);
      // console.log(data);
      var tp = parseFloat(data.tax_percentage);
      return (rate + (rate * tp) / 100).toFixed(2);
    }
  },
  methods: {
    setArchival(e) {
      this.archivalItem = [];
      var id = e.target.value;

      this.archivalDataFetched(id, this.getTotalArchivalRateAmount);
    },

    archivalDataFetched(id, old_archival_amount = 0) {
      var key = this.archivalData.findIndex(x => x.archival_period.id == id);
      // var old_archival_amount = ;
      this.archival_total = 0;
      if (key > -1) {
        this.archivalItem = this.archivalData[key];
        var amount = parseFloat(this.archivalData[key].rate);
        var taxPer = parseFloat(this.archivalData[key].tax_percentage);
        var tax = parseFloat(((amount * taxPer) / 100).toFixed(2));
        this.archival_total = amount + tax;
        this.$store.commit(SET_ARCHIVAL_PERIOD_ID, id);

        // this.$store.commit(SET_QTY, this.qty);
        // var add_rate = parseFloat(this.rateData.rate);
        // var tax_amt = (add_rate * this.rateData.tax_percentage) / 100;
        // var new_amount = (this.qty + 1 * (add_rate + tax_amt)).toFixed(2);

        // this.$store.commit(
        //   SET_ARCHIVAL_TOTAL_RATE_AMOUNT,
        //  0
        // );
      } else {
        this.archivalItem = 0;
        this.$store.commit(SET_ARCHIVAL_PERIOD_ID, "");
      }

      this.$store.commit(
        SET_TOTAL_SERVICE_AMOUNT,
        (
          parseFloat(this.archival_total) +
          parseFloat(this.getTotalServiceAmount) -
          parseFloat(old_archival_amount)
        ).toFixed(2)
      );

      this.$store.commit(
        SET_ARCHIVAL_TOTAL_RATE_AMOUNT,
        parseFloat(this.archival_total)
      );

      // this.setQty();

      // this.$store.commit(SET_ADD_RATE_QTY, this.addQty);
      // var add_rate_ = parseFloat(this.rateData.additional_rate);

      // var tax_percentage = parseFloat(this.rateData.tax_percentage);

      // this.$store.commit(
      //   SET_RATE_TOTAL_AMOUNT,
      //   (
      //     this.addQty * add_rate_ +
      //     (this.addQty * add_rate_ * tax_percentage) / 100 +
      //     this.archival_total
      //   ).toFixed(2)
      // );
    },

    change_abbr() {
      this.abbreviation_code_error = false;
    },
    setQty() {
      this.$store.commit(SET_QTY, this.qty);
      var add_rate = parseFloat(this.rateData.rate);
      var tax_amt = (add_rate * this.rateData.tax_percentage) / 100;

      this.$store.commit(
        SET_RATE_TOTAL_AMOUNT,
        (this.qty * (add_rate + tax_amt)).toFixed(2)
      );

      this.$store.commit(
        SET_TOTAL_SERVICE_AMOUNT,
        (
          parseFloat(this.qty * (add_rate + tax_amt)) +
          parseFloat(this.getTotalArchivalRateAmount)
        ).toFixed(2)
      );
    },
    setMultiRecipient() {
      this.$store.commit(SET_MULTI_RECIPIENT, this.recipient);
    },
    calAdditional() {
      // alert();
      // var add_rate = parseFloat(this.rateData.additional_rate);
      // var tax_percentage = parseFloat(this.rateData.tax_percentage);
      // this.$store.commit(
      //   SET_RATE_TOTAL_AMOUNT,
      //   (
      //     this.addQty * add_rate +
      //     (this.addQty * add_rate * tax_percentage) / 100
      //   ).toFixed(2)
      // );
      // if (this.isBookNowFromSp) {
      //   this.$store.commit(
      //     SET_RATE_TOTAL_AMOUNT,
      //     (
      //       this.addQty * add_rate +
      //       (this.addQty * add_rate * tax_percentage) / 100
      //     ).toFixed(2)
      //   );
      // }
      //  this.$store.commit(
      //     SET_TOTAL_SERVICE_AMOUNT,
      //     (
      //       this.addQty * add_rate +
      //       (this.addQty * add_rate * tax_percentage) / 100
      //     ).toFixed(2)
      //   );
    },
    removeAbbCode() {
      this.$store.commit(UPDATE_ABBREVIATION_CODE, "");
      this.$store.commit(UPDATE_PAYMENT_TYPE, "C");
      //  this.$store.commit(UPDATE_PAYMENT_TYPE,this.getPaymentType);
      this.abbreviation_code_error = false;
      this.$store.commit(SET_CHECK_F_ABBREVIATION_CODE, {
        data: { show_payment_option: true },
        success: "none"
      });
    },
    updateOri(e) {
      this.$store.commit(UPDATE_SERVICE_ORI_CODE, e.target.value);
      this.$store.commit(SET_CHECK_F_SERVICE_ORI_CODE, {
        data: "",
        success: "none"
      });
      //     this.$store.commit(SET_CHECK_F_ABBREVIATION_CODE, {
      //   data: [],success:true
      // });
    },
    updateAbCode(e) {
      this.$store.commit(UPDATE_ABBREVIATION_CODE, e.target.value);
      this.$store.commit(SET_CHECK_F_ABBREVIATION_CODE, {
        data: { show_payment_option: true },
        success: "none"
      });
      //     this.$store.commit(SET_CHECK_F_ABBREVIATION_CODE, {
      //   data: [],success:true
      // });
    },
    change_ori() {
      this.$store.commit(UPDATE_ORI, "");
      this.service_ori_code_error = true;
    },
    removeServiceOri() {
      this.$store.commit(UPDATE_ORI, "");
      //  this.$store.commit(UPDATE_PAYMENT_TYPE,this.getPaymentType);
      this.service_ori_code_error = false;
      this.$store.commit(UPDATE_SERVICE_ORI_CODE, "");
      this.$store.commit(SET_CHECK_F_SERVICE_ORI_CODE, {
        data: "",
        success: "none"
      });
    },
    checkServiceOri() {
      if (this.updateServiceOriCode != "") {
        this.service_ori_code_error = true;
        var service_id = this.getServiceId;
        this.$store
          .dispatch(CHECK_F_SERVICE_ORI_CODE, {
            service_code: this.getServiceTypeCode,
            code: this.updateServiceOriCode,
            client: this.$route.params.id,
            service: service_id
          })
          .then(data => {
            if (data.success) {
              this.$store.commit(UPDATE_SERVICE_ORI_CODE, data.data.ori_number);
              this.$store.commit(UPDATE_ORI, data.data.id);
              this.$store.commit(SET_F_CLIENT_RATE, data.data);
              this.rateData = data.data;
              if (data.data.service.is_drug_testing) {
                this.qty = this.getQty;
                this.recipient = this.getMultirecipient;
              } else {
                this.qty = data.data.service.default_qty;
              }
              var rate = parseFloat(data.data.rate);
              var tax_percentage_amount = parseFloat(
                (
                  (parseFloat(data.data.tax_percentage) / 100) *
                  rate *
                  this.qty
                ).toFixed(2)
              );
              //  (rateData.additional_rate * getAddQty)
              //                   | taxAmount(rateData.tax_percentage)
              var tax_additional_amount =
                (parseFloat(data.data.tax_percentage) / 100) *
                data.data.additional_rate *
                this.getAddQty;
              this.$store.commit(
                SET_ADDITIONAL_CARD_TOTAL_AMOUNT,

                parseFloat(
                  data.data.additional_rate * this.getAddQty +
                    tax_additional_amount
                ).toFixed(2)
              );
              this.$store.commit(
                SET_RATE_TOTAL_AMOUNT,
                parseFloat(rate * this.qty + tax_percentage_amount).toFixed(2)
              );

              this.$store.commit(
                SET_TOTAL_SERVICE_AMOUNT,
                parseFloat(rate * this.qty + tax_percentage_amount).toFixed(2)
              );

              this.service_ori = "";
            } else {
              this.service_ori_code_error = true;
            }
          });
      } else {
        this.service_ori_code_error = true;
      }
    },

    checkAbbreviationCode() {
      this.$store.commit(UPDATE_ABBREVIATION_CODE, this.abree_code);
      if (this.updateAbbreviationCode != "") {
        this.abbreviation_code_error = true;

        this.$store
          .dispatch(CHECK_F_ABBREVIATION_CODE, {
            code: this.updateAbbreviationCode,
            client_slug: this.$route.params.client_slug
          })
          .then(data => {
            if (data.success) {
              if (!data.data.show_payment_option) {
                this.$store.commit(UPDATE_PAYMENT_TYPE, "A");
              }
              this.abree_code = "";
            }
          });
      } else {
        this.abbreviation_code_error = false;
      }
    },
    fetchRate() {
      this.loadingData = true;
      if (this.updateServiceOriCode != "") {
        this.service_ori = this.updateServiceOriCode;
        this.checkServiceOri();
        this.loadingData = false;
      } else {
        var id = this.$route.params.id;
        var service_id = this.getServiceId;

        this.$store
          .dispatch(FETCH_F_CLIENT_RATE, {
            id: id,
            service_id: service_id
          })
          .then(data => {
            this.archival_period = this.getArchivalPeriodId;
            this.rateData = data;
            this.loadingData = false;
            if (data.service.is_drug_testing) {
              this.qty = this.getQty;
              this.recipient = this.getMultirecipient;
            } else {
              this.qty = data.service.default_qty;
            }

            var rate = data.rate;

            var tax_percentage_amount =
              (parseFloat(data.tax_percentage) / 100) * rate * this.qty;
            //  (rateData.additional_rate * getAddQty)
            //                   | taxAmount(rateData.tax_percentage)
            var tax_additional_amount =
              (parseFloat(data.tax_percentage) / 100) *
              data.additional_rate *
              this.getAddQty;
            this.$store.commit(
              SET_ADDITIONAL_CARD_TOTAL_AMOUNT,

              (
                data.additional_rate * this.getAddQty +
                tax_additional_amount
              ).toFixed(2)
            );

            this.$store.commit(
              SET_RATE_TOTAL_AMOUNT,

              (rate * this.qty + tax_percentage_amount).toFixed(2)
            );

            this.$store.commit(
              SET_TOTAL_SERVICE_AMOUNT,

              (
                rate * this.qty +
                tax_percentage_amount +
                parseFloat(this.getTotalAdditionalAmount)
              ).toFixed(2)
            );

            this.archivalDataFetched(this.archival_period);

            // this.setQty();

            // this.$store.commit(
            //   SET_TOTAL_SERVICE_AMOUNT,
            //   (ccc
            //     parseFloat(this.qty * (add_rate + tax_amt)) +
            //     parseFloat(this.archival_total)
            //   ).toFixed(2)
            // );

            // this.calAdditional();
          })
          .catch(() => {
            this.loadingData = false;
          });
      }
    },
    addQty_() {
      this.addQty = this.addQty + 1;
      this.$store.commit(SET_ADD_RATE_QTY, this.addQty);
      var add_rate = parseFloat(this.rateData.additional_rate);
      var tax_percentage = parseFloat(this.rateData.tax_percentage);

      this.$store.commit(
        SET_ADDITIONAL_CARD_TOTAL_AMOUNT,
        (
          this.addQty * add_rate +
          (this.addQty * add_rate * tax_percentage) / 100
        ).toFixed(2)
      );

      this.$store.commit(
        SET_TOTAL_SERVICE_AMOUNT,
        (
          parseFloat(this.getTotalAdditionalAmount) +
          parseFloat(this.getTotalRateAmount) +
          parseFloat(this.getTotalArchivalRateAmount)
        ).toFixed(2)
      );
    },
    miuQty_() {
      if (this.addQty > 0) {
        this.addQty = this.addQty - 1;
        this.$store.commit(SET_ADD_RATE_QTY, this.addQty);
        var add_rate = parseFloat(this.rateData.additional_rate);
        var tax_percentage = parseFloat(this.rateData.tax_percentage);
        this.$store.commit(
          SET_ADDITIONAL_CARD_TOTAL_AMOUNT,
          (
            this.addQty * add_rate +
            (this.addQty * add_rate * tax_percentage) / 100
          ).toFixed(2)
        );

        this.$store.commit(
          SET_TOTAL_SERVICE_AMOUNT,
          (
            parseFloat(this.getTotalAdditionalAmount) +
            parseFloat(this.getTotalRateAmount) +
            parseFloat(this.getTotalArchivalRateAmount)
          ).toFixed(2)
        );
      }
    }
  }
};
</script>
