<style lang="scss">
#personal-form {
  .form-control {
    height: 40px;
  }
}
.row .dob_res {
  margin-bottom: 2%;
}
</style>
<template>
  <section id="personal-form">
    <div class="row">
      <div class="col-lg-12">
        <h5 class="mb-5">Personal Identity Information</h5>
        <div class="row">
          <div class="col-md-12">
            <div
              class="
                alert alert-custom alert-danger-dim alert-shadow
                fade
                show
                gutter-b
              "
              role="alert"
              v-if="getSettingNote.personal_identity_note != ''"
            >
              <div class="alert-text">
                <div v-html="getSettingNote.personal_identity_note"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-3">
            <label for="exampleInputEmail1">Social Security No.</label>
            <input
              type="text"
              class="form-control"
              placeholder="Social Security Number"
              v-mask="'###-##-####'"
              :value="getApplicantSSN"
              @input="updateSSN"
              :class="errorMsg['ssn'] ? 'border border-danger' : ''"
            />
            <span v-if="errorMsg['ssn']" class="text-danger">{{
              errorMsg["ssn"][0]
            }}</span>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12 col-12 form-group mb-3 pr-5">
            <label for="exampleInputEmail1"
              >Date of Birth <span class="text-danger">*</span></label
            >
            <div class="row">
              <div class="col-4 dob_res pr-0">
                <select
                  class="form-control"
                  v-model="dob_m"
                  @change="updateDateOfBirthMonth()"
                >
                  <option value="">Month</option>
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
              </div>
              <div class="col-4 dob_res pl-2 pr-0">
                <select
                  class="form-control"
                  v-model="dob_d"
                  @change="updateDateOfBirthDay()"
                >
                  <option value="">Day</option>
                  <option v-for="day in days" :key="day" :value="day">
                    {{ day }}
                  </option>
                </select>
              </div>
              <div class="col-4 dob_res pl-2 pr-0">
                <select
                  class="form-control"
                  v-model="dob_y"
                  @change="updateDateOfBirthYear()"
                >
                  <option value="">Year</option>
                  <option v-for="year in years" :key="year" :value="year">
                    {{ year }}
                  </option>
                </select>
              </div>
            </div>

            <!-- <date-picker
              v-model="date_of_birth"
              @change="updateDateOfBirth()"
              valueType="MM/DD/YYYY"
              format="MM/DD/YYYY"
              :disabled-date="notAfterToday"
            ></date-picker> -->
          </div>

          <!-- <div
            class="col-lg-1 col-md-1 col-sm-3 col-2 form-group mb-3 pl-1 pr-1"
          >
            <select class="form-control" style="margin-top: 25px">
              <option value="">Day</option>
              <option v-for="day in days" :key="day" :value="day">
                {{ day }}
              </option>
            </select>
          </div>

          <div
            class="col-lg-1 col-md-1 col-sm-2 col-3 form-group mb-3 pr-0 pl-1"
          >
            <select class="form-control" style="margin-top: 25px">
              <option value="">Year</option>
              <option v-for="year in years" :key="year" :value="year">
                {{ year }}
              </option>
            </select>
          </div> -->

          <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-3">
            <label for="exampleInputEmail1"
              >Place of Birth<span class="text-danger">*</span></label
            >
            <select class="form-control" @change="updatePlaceOfBirth">
              <option value="" hidden>Select Place of Birth</option>
              <option
                v-for="(item, index) in getPlaceOfBirth"
                :key="index"
                :value="item.name"
                :selected="getApplicantPlaceOfBirth == item.name"
              >
                {{ item.name }}
              </option>
            </select>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-3">
            <label for="exampleInputEmail1"
              >Country of Citizenship<span class="text-danger">*</span></label
            >
            <select class="form-control" @change="updateCountry">
              <option value="" hidden>Select Country</option>
              <option
                v-for="(item, index) in getCountries"
                :key="index"
                :value="item.name"
                :selected="getApplicantcounrtyOfCitizeb == item.name"
              >
                {{ item.name }}
              </option>
            </select>
          </div>

          <div
            class="
              col-lg-2 col-md-2 col-sm-6 col-xs-6 col-6
              form-group
              mb-3
              pr-1
            "
          >
            <label for="exampleInputEmail1"
              >Gender<span class="text-danger">*</span></label
            >

            <select
              class="form-control valid"
              style="max-width: 20em"
              data-val="true"
              data-val-required="The Gender field is required."
              id="Input_Gender"
              name="Input.Gender"
              aria-describedby="Input_Gender-error"
              aria-invalid="false"
              @change="updateGender"
            >
              <option value="" hidden :selected="getApplicantGender == ''">
                Select Gender
              </option>
              <option :selected="getApplicantGender == 'Female'" value="Female">
                Female
              </option>
              <option :selected="getApplicantGender == 'Male'" value="Male">
                Male
              </option>
              <option :selected="getApplicantGender == 'Other'" value="Other">
                Other
              </option>
            </select>
          </div>

          <div class="col-lg-2 col-md-2 col-sm-12 col-6 form-group mb-3 pl-1">
            <label for="exampleInputEmail1"
              >Race<span class="text-danger">*</span></label
            >
            <select class="form-control" @change="updateRace">
              <option value="" hidden>Select Race</option>
              <option
                v-for="(item, index) in getRaces"
                :key="index"
                :value="item.name"
                :selected="getApplicantRace == item.name"
              >
                {{ item.name }}
              </option>
            </select>
          </div>

          <div
            class="
              col-lg-2 col-md-2 col-sm-6 col-xs-6 col-6
              form-group
              mb-3
              pr-1
            "
          >
            <label for="exampleInputEmail1"
              >Eye Color<span class="text-danger">*</span></label
            >
            <select class="form-control" @change="updateEyeColor">
              <option value="" hidden>Select Eye Color</option>
              <option
                v-for="(item, index) in getEyeColors"
                :key="index"
                :value="item.name"
                :selected="getApplicantEyeColor == item.name"
              >
                {{ item.name }}
              </option>
            </select>
          </div>

          <div class="col-lg-2 col-md-2 col-sm-3 col-6 form-group mb-3 pl-1">
            <label for="exampleInputEmail1"
              >Hair Color<span class="text-danger">*</span></label
            >
            <select class="form-control" @change="updateHairColor">
              <option value="" hidden>Select Hair Color</option>
              <option
                v-for="(item, index) in getHairColors"
                :key="index"
                :value="item.name"
                :selected="getApplicantHairColor == item.name"
              >
                {{ item.name }}
              </option>
            </select>
          </div>

          <div
            class="col-lg-2 col-md-2 col-sm-12 col-6 form-group mb-3 pr-1"
            style="position: relative"
          >
            <label for="exampleInputEmail1"
              >Height<span class="text-danger">*</span></label
            >
            <!-- <input type="text"   placeholder="Heigh in ft.in" class="form-control"  :value="getApplicantHeight" @input="updateHeight" v-mask="'##.##'"> -->
            <span
              style="
                background: #c9c9c9;
                position: absolute;
                top: 24px;
                right: 3px;
                line-height: 40px;
                width: 25px;
                text-align: center;
                border-bottom-right-radius: 5px;
                border-top-right-radius: 5px;
              "
              >ft</span
            >
            <select
              @change="heightUpdate()"
              v-model="heightFt"
              class="form-control"
              data-val="true"
              data-val-required="Inches are required.<br/>"
              id="Input_HeightInches"
              name="Input.HeightInches"
            >
              <option value="" hidden selected="">Ft.</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
            </select>
          </div>

          <div
            class="col-lg-2 col-md-3 col-sm-3 col-6 form-group mb-3 pl-1 pr-4"
            style="position: relative"
          >
            <span
              style="
                background: #c9c9c9;
                position: absolute;
                top: 25px;
                right: 12px;
                line-height: 40px;
                width: 25px;
                text-align: center;
                border-bottom-right-radius: 5px;
                border-top-right-radius: 5px;
              "
              >in</span
            >
            <select
              @change="heightUpdate()"
              v-model="heightIn"
              class="form-control"
              style="margin-top: 25px"
              data-val="true"
              data-val-required="Inches are required.<br/>"
              id="Input_HeightInches"
              name="Input.HeightInches"
            >
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
            </select>
          </div>
          <!-- <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-3">
            <label for="exampleInputEmail1"
              >Weight(lb)<span class="text-danger">*</span></label
            >

            <input
              type="text"
              class="form-control"
              v-mask="'###'"
              :value="getApplicantWeight"
              @input="updateWeight"
              :class="errorMsg['weight'] ? 'border border-danger' : ''"
            />
            <span v-if="errorMsg['weight']" class="text-danger">{{
              errorMsg["weight"][0]
            }}</span>
          </div> -->

          <div class="col-lg-4 col-md-4 col-sm-12 col-12 form-group mb-3">
            <label for="exampleInputEmail1"
              >Weight(lb)<span class="text-danger">*</span></label
            >

            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                v-mask="'###'"
                :value="getApplicantWeight"
                @input="updateWeight"
                :class="errorMsg['weight'] ? 'border border-danger' : ''"
              />
              <div class="input-group-append">
                <span class="input-group-text" id="basic-addon2">lb</span>
              </div>
            </div>
            <span v-if="errorMsg['weight']" class="text-danger">{{
              errorMsg["weight"][0]
            }}</span>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-3"></div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
// import Title from '../../components/frontend/title.vue';

import { mapGetters } from "vuex";
import {
  FETCH_COUNTRIES,
  FETCH_RACES,
  FETCH_EYECOLORS,
  FETCH_HAIRCOLORS,
  FETCH_PlACE_OF_BIRTH
} from "@/core/services/store/actions.type";
import {
  UPDATE_SOCIAL_SECURITY,
  UPDATE_WEIGHT,
  UPDATE_HAIRCOLOR,
  UPDATE_EYECOLOR,
  UPDATE_RACE,
  UPDATE_GENDER,
  UPDATE_COUNTRY,
  UPDATE_PLACE_OF_BIRTH,
  UPDATE_DATEOFBIRTH,
  UPDATE_HEIGHT,
  UPDATE_DATEOFBIRTH_MONTH,
  UPDATE_DATEOFBIRTH_DAY,
  UPDATE_DATEOFBIRTH_YEAR
} from "@/core/services/store/mutations.type";
// import moment from "moment";
export default {
  computed: {
    years() {
      const year = new Date().getFullYear() + 1;
      return Array.from(
        { length: year - 1900 },
        (value, index) => 1900 + index
      );
    },
    days() {
      const day = 33;
      return Array.from({ length: day - 1 }, (value, index) => 1 + index);
    },
    months() {
      const month = 13;
      return Array.from({ length: month - 1 }, (value, index) => 1 + index);
    },
    dob() {
      return (
        this.minTwoDigits(this.dob_m) +
        "/" +
        this.minTwoDigits(this.dob_d) +
        "/" +
        this.dob_y
      );
    },
    ...mapGetters([
      "getCountries",
      "getRaces",
      "getEyeColors",
      "getHairColors",
      "getPlaceOfBirth",
      "getApplicantWeight",
      "getApplicantSSN",
      "getApplicantPlaceOfBirth",
      "getApplicantcounrtyOfCitizeb",
      "getApplicantGender",
      "getApplicantRace",
      "getApplicantEyeColor",
      "getApplicantHairColor",
      "getApplicantDateOfBirth",
      "getApplicantHeight",
      "getSettingNote",
      "getApplicantDobMonth",
      "getApplicantDobDay",
      "getApplicantDobYear"
    ])
  },
  created() {
    this.$store.dispatch(FETCH_COUNTRIES);
    this.$store.dispatch(FETCH_RACES);
    this.$store.dispatch(FETCH_EYECOLORS);
    this.$store.dispatch(FETCH_HAIRCOLORS);
    this.$store.dispatch(FETCH_PlACE_OF_BIRTH);
    this.date_of_birth =
      this.getApplicantDateOfBirth != "" ? this.getApplicantDateOfBirth : "";
    if (this.getApplicantHeight != "") {
      var hFt = this.getApplicantHeight.split(".");
      this.heightFt = hFt[0];
      this.heightIn = hFt[1];
    }
    this.dob_m = this.getApplicantDobMonth;
    this.dob_d = this.getApplicantDobDay;
    this.dob_y = this.getApplicantDobYear;
  },
  methods: {
    minTwoDigits(n) {
      return (n < 10 ? "0" : "") + n;
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
    heightUpdate() {
      // alert('asdasdas');
      var height = this.heightFt + "." + this.heightIn;
      this.updateHeight(height);
    },
    updateSSN(e) {
      this.$store.commit(UPDATE_SOCIAL_SECURITY, e.target.value);
    },
    updateWeight(e) {
      this.$store.commit(UPDATE_WEIGHT, e.target.value);
    },
    updatePlaceOfBirth(e) {
      this.$store.commit(UPDATE_PLACE_OF_BIRTH, e.target.value);
    },
    updateCountry(e) {
      this.$store.commit(UPDATE_COUNTRY, e.target.value);
    },
    updateGender(e) {
      this.$store.commit(UPDATE_GENDER, e.target.value);
    },
    updateRace(e) {
      this.$store.commit(UPDATE_RACE, e.target.value);
    },
    updateEyeColor(e) {
      this.$store.commit(UPDATE_EYECOLOR, e.target.value);
    },
    updateHairColor(e) {
      this.$store.commit(UPDATE_HAIRCOLOR, e.target.value);
    },
    updateDateOfBirth() {
      this.$store.commit(UPDATE_DATEOFBIRTH, this.date_of_birth);
    },
    updateDateOfBirthMonth() {
      this.$store.commit(UPDATE_DATEOFBIRTH_MONTH, this.dob_m);
      this.$store.commit(UPDATE_DATEOFBIRTH, this.dob);
    },
    updateDateOfBirthDay() {
      this.$store.commit(UPDATE_DATEOFBIRTH_DAY, this.dob_d);
      this.$store.commit(UPDATE_DATEOFBIRTH, this.dob);
    },
    updateDateOfBirthYear() {
      this.$store.commit(UPDATE_DATEOFBIRTH_YEAR, this.dob_y);
      this.$store.commit(UPDATE_DATEOFBIRTH, this.dob);
    },
    updateHeight(value) {
      this.$store.commit(UPDATE_HEIGHT, value);
    }
  },
  data() {
    return {
      date_of_birth: "",
      heightFt: "",
      heightIn: "0",
      myInputModel: "",
      dob_m: "",
      dob_d: "",
      dob_y: "",

      message:
        "<b>Please enter all of your information CAREFULLY.</b><br> To protect your privacy, your personal information will not be available for review at the time of your appointment. Fingerprinting services are not refundable. If your information is submitted incorrectly, it will result in a rejection and you will be forced to pay for the service again."
    };
  },
  props: {
    errorMsg: {
      type: Array,
      default: () => []
    }
  }
};
</script>
