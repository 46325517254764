<style lang="scss">
#contact-form {
  .form-control {
    height: 40px;
  }
}
</style>
<template>
  <section id="contact-form">
    <v-countries-modal
      :li-scrolls="id_scroll_list_country"
      :show-modal="showCountriesWithPhoneCode"
      v-on:closemodal="toggleCountriesPhoneCode"
    ></v-countries-modal>
    <div class="row">
      <div class="col-lg-12">
        <h5 class="mb-5">Contact Information</h5>
        <p>Please enter all the required field *</p>
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
            <label for="exampleInputEmail1"
              >First Name <span class="text-danger">*</span></label
            >
            <input
              type="text"
              class="form-control"
              :value="getApplicantFirstName"
              @input="updateFirstName"
              :class="errorMsg['fname'] ? 'border border-danger' : ''"
            />
            <span v-if="errorMsg['fname']" class="text-danger">{{
              errorMsg["fname"][0]
            }}</span>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
            <label for="exampleInputEmail1">Middle Name</label>
            <input
              type="text"
              class="form-control"
              :value="getApplicantMiddleName"
              @input="updateMiddleName"
              :class="errorMsg['mname'] ? 'border border-danger' : ''"
            />
            <span v-if="errorMsg['mname']" class="text-danger">{{
              errorMsg["mname"][0]
            }}</span>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
            <label for="exampleInputEmail1"
              >Last Name<span class="text-danger">*</span></label
            >
            <input
              type="text"
              class="form-control"
              :value="getApplicantlastName"
              @input="updateLastName"
              :class="errorMsg['lname'] ? 'border border-danger' : ''"
            />
            <span v-if="errorMsg['lname']" class="text-danger">{{
              errorMsg["lname"][0]
            }}</span>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
            <label for="exampleInputEmail1"
              >Email<span class="text-danger">*</span></label
            >
            <input
              type="email"
              class="form-control"
              :value="getApplicantEmail"
              @input="updateEmail"
            />
            <span
              v-if="errorMsg['email'] && getApplicantEmail != ''"
              class="text-danger"
              >{{ errorMsg["email"][0] }}</span
            >
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
            <label for="exampleInputEmail1"
              >Confirm Email<span class="text-danger">*</span></label
            >
            <input
              type="email"
              class="form-control"
              :value="getApplicantConfirmEmail"
              @input="updateConfirmEmail"
              @click.right.prevent
              @paste.prevent
            />
            <span
              v-if="
                getApplicantConfirmEmail != getApplicantEmail &&
                  getApplicantConfirmEmail != ''
              "
              class="text-danger"
              >Confirm email doesn't matched with email</span
            >
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
            <label for="exampleInputEmail1"
              >Phone No.<span class="text-danger">*</span></label
            >
            <div class="input-group mb-3">
              <div
                class="input-group-prepend"
                @click="toggleCountriesPhoneCode(true)"
                style="cursor:pointer;border-right:1px solid"
              >
                <span
                  class="input-group-text"
                  id="basic-addon1"
                  style="min-width: 80px;max-width: 80px; text-align: center;"
                >
                  <img
                    :src="getSelectedCountryPhoneCode.flag"
                    alt=""
                    class="mr-1"
                  />
                  <b style="width: 100%;text-align: center;">{{
                    getSelectedCountryPhoneCode.dial_code
                  }}</b>
                </span>
              </div>
              <input
                type="text"
                class="form-control"
                :value="getApplicantPhoneNo"
                @input="updatePhoneNo"
                v-mask="'(###)-###-####'"
              />
            </div>
            <span
              v-if="errorMsg['phone_no'] && getApplicantEmail != ''"
              class="text-danger"
              >{{ errorMsg["phone_no"][0] }}</span
            >
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
            <label for="exampleInputEmail1"
              >Address Line 1<span class="text-danger">*</span></label
            >
            <input
              type="text"
              class="form-control"
              :value="getApplicantAddressLine1"
              @input="updateAddresLine1"
            />
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
            <label for="exampleInputEmail1">Address Line 2</label>
            <input
              type="text"
              class="form-control"
              :value="getApplicantAddressLine2"
              @input="updateAddresLine2"
            />
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
            <label for="exampleInputEmail1"
              >City <span class="text-danger">*</span></label
            >
            <!-- //updateCity  getApplicantCity -->
            <input
              type="text"
              @change="updateCity"
              class="form-control"
              :value="getApplicantCity"
            />
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
            <label for="exampleInputEmail1"
              >State<span class="text-danger">*</span></label
            >
            <select class="form-control" @change="updateState">
              <option value="">Select State</option>
              <option
                v-for="(item, index) in getStates"
                :key="index"
                :value="item.name"
                :selected="getApplicantState == item.name"
              >
                {{ item.name }}
              </option>
            </select>
          </div>

          <div class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
            <label for="exampleInputEmail1"
              >Zip Code<span class="text-danger">*</span></label
            >
            <input
              type="text"
              class="form-control"
              :value="getApplicantZipCode"
              @input="updateZipCode"
              v-mask="'#####'"
            />
          </div>

          <div
            class="col-lg-4 col-md-4 col-sm-12 form-group mb-2"
            v-if="getServiceTypeCode == 'fdle'"
          >
            <label for="exampleInputEmail1"
              >Reason for taking this service
              <span class="text-danger">*</span></label
            >
            <select
              class="form-control"
              @change="updateReascon"
              :value="getApplicantReason"
            >
              <option value="">Select Reason</option>
              <option value="Firearms">Firearms</option>
              <option value="Volunteer">
                Volunteer
              </option>
              <option value="Criminal Justice Employment">
                Criminal Justice Employment
              </option>
              <option value="Child Care/School Employee">
                Child Care/School Employee
              </option>
              <option value="Law Enforcement">
                Law Enforcement
              </option>
              <option value="Other Employment and Licensing">
                Other Employment and Licensing
              </option>
            </select>
            <span style="font-style: italic;text-align: right;font-size: 10px;">
              Please be as specific as possible
            </span>
          </div>

          <div v-else class="col-lg-4 col-md-4 col-sm-12 form-group mb-2">
            <label for="exampleInputEmail1"
              >Reason for taking this service
              <span class="text-danger">*</span></label
            >
            <input
              type="text"
              class="form-control"
              :value="getApplicantReason"
              @input="updateReascon"
            />
            <span style="font-style: italic;text-align: right;font-size: 10px;">
              Please be as specific as possible
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import { FETCH_STATES } from "@/core/services/store/actions.type";
import {
  UPDATE_REASONFORFINGERPRINT,
  UPDATE_ZIPCODE,
  UPDATE_ADDRESSLINE2,
  UPDATE_ADDRESSLINE1,
  UPDATE_PHONENO,
  UPDATE_CONFIRMEMAIL,
  UPDATE_EMAIL,
  UPDATE_LASTNAME,
  UPDATE_MIDDLENAME,
  UPDATE_FIRSTNAME,
  UPDATE_STATE,
  UPDATE_CITY
} from "@/core/services/store/mutations.type";
import vCountriesForPhoneCode from "@/components/frontend/modal/countriesforphonecode";
// import Title from '../../components/frontend/title.vue';
export default {
  components: {
    "v-countries-modal": vCountriesForPhoneCode
  },
  computed: {
    ...mapGetters([
      "getCities",
      "getStates",
      "getApplicantFirstName",
      "getApplicantMiddleName",
      "getApplicantlastName",
      "getApplicantEmail",
      "getApplicantConfirmEmail",
      "getApplicantPhoneNo",
      "getApplicantAddressLine1",
      "getApplicantAddressLine2",
      "getApplicantZipCode",
      "getApplicantReason",
      "getServiceTypeCode",
      "getApplicantCity",
      "getApplicantState",
      "getSelectedCountryPhoneCode"
    ])
  },
  data() {
    return {
      id_scroll_list_country: "",
      showCountriesWithPhoneCode: false,
      regEmail: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      errors: []
    };
  },
  props: {
    errorMsg: {
      type: Array,
      default: () => []
    }
  },

  created() {
    // this.$store.dispatch(FETCH_CITIES);
    this.$store.dispatch(FETCH_STATES);
  },
  methods: {
    toggleCountriesPhoneCode(flag) {
      if (flag) {
        this.id_scroll_list_country =
          "scroll_li" + this.getSelectedCountryPhoneCode.code;
      }
      this.showCountriesWithPhoneCode = flag;
    },
    validateEmail(value) {
      if (this.regEmail.test(value)) {
        this.errors["email"] = "";
      } else {
        this.errors["email"] = "Please enter a valid email address";
      }
    },
    updateFirstName(e) {
      this.$store.commit(UPDATE_FIRSTNAME, e.target.value);
    },
    updateMiddleName(e) {
      this.$store.commit(UPDATE_MIDDLENAME, e.target.value);
    },
    updateLastName(e) {
      this.$store.commit(UPDATE_LASTNAME, e.target.value);
    },
    updateEmail(e) {
      this.validateEmail(e.target.value);
      this.$store.commit(UPDATE_EMAIL, e.target.value);
    },
    updateConfirmEmail(e) {
      this.$store.commit(UPDATE_CONFIRMEMAIL, e.target.value);
    },
    updatePhoneNo(e) {
      this.$store.commit(UPDATE_PHONENO, e.target.value);
    },
    updateAddresLine1(e) {
      this.$store.commit(UPDATE_ADDRESSLINE1, e.target.value);
    },
    updateAddresLine2(e) {
      this.$store.commit(UPDATE_ADDRESSLINE2, e.target.value);
    },
    updateZipCode(e) {
      this.$store.commit(UPDATE_ZIPCODE, e.target.value);
    },
    updateReascon(e) {
      this.$store.commit(UPDATE_REASONFORFINGERPRINT, e.target.value);
    },
    updateState(e) {
      this.$store.commit(UPDATE_STATE, e.target.value);
    },
    updateCity(e) {
      this.$store.commit(UPDATE_CITY, e.target.value);
    }
  }
};
</script>
